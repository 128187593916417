import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { SentInvoiceListItem } from '@models';
import { IApiRequest, IApiResponse } from '../../interfaces';

export const SentInvoicesActions = createActionGroup({
  source: 'SentInvoices',
  events: {
    load: emptyProps(),
    loaded: props<{ response: IApiResponse<SentInvoiceListItem[]> }>(),
    loadError: props<{ error: any }>(),
    lastApiRequest: props<{ apiRequest: IApiRequest }>(),
  },
});
