import { on } from '@ngrx/store';
import { SentInvoicesActions } from './sent-invoices.actions';
import { ISentInvoicesState, initialState } from './sent-invoices.state';
import { createImmerReducer } from 'ngrx-immer/store';

export const sentInvoicesReducer = createImmerReducer<ISentInvoicesState>(
  initialState,
  on(SentInvoicesActions.load, state => {
    state.isLoading = true;
    state.error = null;
    return state;
  }),
  on(SentInvoicesActions.loaded, (state, { response }) => {
    state.isLoading = false;
    state.response = response;
    state.error = null;
    return state;
  }),
  on(SentInvoicesActions.loadError, (state, { error }) => {
    state.error = error;
    state.isLoading = false;
    return state;
  }),
  on(SentInvoicesActions.lastApiRequest, (state, { apiRequest }) => {
    state.lastApiRequest = apiRequest;
    return state;
  })
);
