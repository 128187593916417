import { SentInvoiceListItem } from '@models';
import { IApiRequest, IApiResponse } from '../../interfaces';

export interface ISentInvoicesState {
  response: IApiResponse<SentInvoiceListItem[]>;
  isLoading: boolean;
  error: any;
  lastApiRequest: IApiRequest;
}

export const initialState: ISentInvoicesState = {
  response: null,
  isLoading: false,
  error: null,
  lastApiRequest: null,
};
