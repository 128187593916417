import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ReceivedInvoiceListItem } from '@models';
import { IApiRequest, IApiResponse } from '../../interfaces';

export const ReceivedInvoicesActions = createActionGroup({
  source: 'ReceivedInvoices',
  events: {
    load: emptyProps(),
    loaded: props<{ response: IApiResponse<ReceivedInvoiceListItem[]> }>(),
    loadError: props<{ error: any }>(),
    lastApiRequest: props<{ apiRequest: IApiRequest }>(),
  },
});
